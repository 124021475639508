/* eslint-disable linebreak-style */
import es from 'vuetify/es5/locale/es';

export default {
  label: 'Español',
  login: {
    login: 'Iniciar Sesión',
    username: 'Nombre de usuario',
    password: 'Contraseña',
  },
  menu: {
    home: 'Home',
    reports: 'Reportes',
    users: 'Usuarios',
    cameras: 'Camaras',
    bus: 'Transporte',
    devices: 'Unidades',
    calendars: 'Calendarios',
    drivers: 'Conductores',
    groups: 'Grupos',
    maintenance: 'Mantenimiento',
    geofences: 'Geocercas',
    incidents: 'Incidentes',
    lpr: 'LPRs',
    sensor: 'Botones de pánico',
    fuelPrice: 'Precio del combustible',
    profiles: 'Perfiles',
  },
  profiles: {
    name: 'Perfil',
    plural_name: 'Perfiles',
    create: 'Nuevo perfil',
    update: 'Actualizar perfil',
    read: 'Ver perfil',
    delete: 'Eliminar perfil',
    delete_confirm: '¿Estás seguro de eliminar el perfil?'
  },
  users: {
    name: 'Usuario',
    plural_name: 'Usuarios',
    create: 'Nuevo usuario',
    update: 'Actualizar usuario',
    read: 'Ver usuario',
    delete: 'Eliminar usuario',
    delete_confirm: 'Estás seguro de eliminar el usuario?',
    permissions: 'Permisos'
  },
  devices: {
    name: 'Unidad',
    plural_name: 'Unidades',
    create: 'Nuevo unidad',
    update: 'Actualizar unidad',
    read: 'Ver unidad',
    delete: 'Eliminar unidad',
    delete_confirm: 'Estás seguro de eliminar el unidad?'
  },
  calendars: {
    name: 'Calendario',
    plural_name: 'Calendarios',
    create: 'Nuevo calendario',
    update: 'Actualizar calendario',
    read: 'Ver calendario',
    delete: 'Eliminar calendario',
    delete_confirm: 'Estás seguro de eliminar el calendario?'
  },
  drivers: {
    name: 'Conductor',
    plural_name: 'Conductores',
    create: 'Nuevo conductor',
    update: 'Actualizar conductor',
    read: 'Ver conductor',
    delete: 'Eliminar conductor',
    delete_confirm: 'Estás seguro de eliminar el conductor?'
  },
  groups: {
    name: 'Grupo',
    plural_name: 'Grupos',
    create: 'Nuevo grupo',
    update: 'Actualizar grupo',
    read: 'Ver grupo',
    delete: 'Eliminar grupo',
    delete_confirm: 'Estás seguro de eliminar el grupo?'
  },
  cameras: {
    name: 'Camara',
    plural_name: 'Camaras',
    create: 'Nueva camara',
    update: 'Actualizar camara',
    read: 'Ver camara',
    delete: 'Eliminar camara',
    delete_confirm: 'Estás seguro de eliminar la camara?'
  },
  bus: {
    name: 'Transporte',
    plural_name: 'Transportes',
    create: 'Nuevo transporte',
    update: 'Actualizar transporte',
    read: 'Ver transporte',
    delete: 'Eliminar transporte',
    delete_confirm: 'Estás seguro de eliminar el transporte?'
  },
  geofences: {
    name: 'Geocerca',
    plural_name: 'Geocercas',
    create: 'Nueva geocerca',
    update: 'Actualizar geocerca',
    read: 'Ver geocerca',
    delete: 'Eliminar geocerca',
    delete_confirm: 'Estás seguro de eliminar la geocerca?'
  },
  incidents: {
    name: 'Incidente',
    plural_name: 'Incidentes',
    create: 'Nuevo incidente',
    update: 'Actualizar incidente',
    read: 'Ver incidente',
    delete: 'Eliminar incidente',
    delete_confirm: 'Estás seguro de eliminar el incidente?'
  },
  lpr: {
    name: 'LPR',
    plural_name: 'LPR',
    create: 'Nuevo LPR',
    update: 'Actualizar LPR',
    read: 'Ver LPR',
    delete: 'Eliminar LPR',
    delete_confirm: 'Estás seguro de eliminar el LPR?'
  },
  sensor: {
    name: 'Botón de pánico',
    plural_name: 'Botones de pánico',
    create: 'Nuevo botón de pánico',
    update: 'Actualizar botón de pánico',
    read: 'Ver botón de pánico',
    delete: 'Eliminar botón de pánico',
    delete_confirm: 'Estás seguro de eliminar el botón de pánico?'
  },
  maintenance: {
    name: 'Mantenimiento',
    plural_name: 'Mantenimientos',
    create: 'Nuevo mantenimiento',
    update: 'Actualizar mantenimiento',
    read: 'Ver mantenimiento',
    delete: 'Eliminar mantenimiento',
    delete_confirm: 'Estás seguro de eliminar el mantenimiento?'
  },
  fuelPrice: {
    name: 'Precio del combustible',
    plural_name: 'Precios del combustible',
    create: 'Nuevo precio del combustible',
    update: 'Actualizar precio del combustible',
    read: 'Ver precio del combustible',
    delete: 'Eliminar precio del combustible',
    delete_confirm: 'Estás seguro de eliminar el precio del combustible?'
  },
  rule: {
    required: 'Campo requerido',
  },
  ...es,
};
