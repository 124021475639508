import SensorIcon from './SensorIcon.vue';
import SensorWhiteIcon from './SensorWhiteIcon.vue';
import CalendarIcon from './CalendarIcon.vue';
import CameraIcon from './CameraIcon.vue';
import CameraWhiteIcon from './CameraWhiteIcon.vue';
import DriverIcon from './DriverIcon.vue';
import DeviceIcon from './DeviceIcon.vue';
import DeviceWhiteIcon from './DeviceWhiteIcon.vue';
import GeofenceIcon from './GeofenceIcon.vue';
import GeofenceWhiteIcon from './GeofenceWhiteIcon.vue';
import GroupIcon from './GroupIcon.vue';
import HomeIcon from './HomeIcon.vue';
import LprIcon from './LprIcon.vue';
import LprWhiteIcon from './LprWhiteIcon.vue';
import BusIcon from './BusIcon.vue';
import BusWhiteIcon from './BusWhiteIcon.vue';
import UserIcon from './UserIcon.vue';
import IncidenteMedicoIcon from './IncidenteMedicoIcon.vue';
import IncidenteSeguridadIcon from './IncidenteSeguridadIcon.vue';
import IncidenteProteccionCivilIcon from './IncidenteProteccionCivilIcon.vue';
import IncidenteServiciosPublicosIcon from './IncidenteServiciosPublicosIcon.vue';
import UberIcon from './UberIcon.vue';
import SensorRelieveIcon from './SensorRelieveIcon.vue';
import CameraRelieveIcon from './CameraRelieveIcon.vue';
import DeviceRelieveIcon from './DeviceRelieveIcon.vue';
import BusRelieveIcon from './BusRelieveIcon.vue';

export default {
  sensor: {
    component: SensorIcon,
  },
  'sensor-white': {
    component: SensorWhiteIcon,
  },
  'sensor-relieve': {
    component: SensorRelieveIcon,
  },
  calendar: {
    component: CalendarIcon,
  },
  camera: {
    component: CameraIcon,
  },
  'camera-white': {
    component: CameraWhiteIcon,
  },
  'camera-relieve': {
    component: CameraRelieveIcon,
  },
  driver: {
    component: DriverIcon,
  },
  device: {
    component: DeviceIcon,
  },
  'device-white': {
    component: DeviceWhiteIcon,
  },
  'device-relieve': {
    component: DeviceRelieveIcon,
  },
  geofence: {
    component: GeofenceIcon,
  },
  'geofence-white': {
    component: GeofenceWhiteIcon,
  },
  group: {
    component: GroupIcon,
  },
  home: {
    component: HomeIcon,
  },
  lpr: {
    component: LprIcon,
  },
  'lpr-white': {
    component: LprWhiteIcon,
  },
  bus: {
    component: BusIcon,
  },
  'bus-white': {
    component: BusWhiteIcon,
  },
  'bus-relieve': {
    component: BusRelieveIcon,
  },
  user: {
    component: UserIcon,
  },
  'incidente-medico': {
    component: IncidenteMedicoIcon,
  },
  'incidente-seguridad': {
    component: IncidenteSeguridadIcon,
  },
  'incidente-proteccion_civil': {
    component: IncidenteProteccionCivilIcon,
  },
  'incidente-servicios_publicos': {
    component: IncidenteServiciosPublicosIcon,
  },
  uber: {
    component: UberIcon,
  },
};
